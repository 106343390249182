/* eslint-disable */
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { createAndRedirectToNewHub } from '../../utils/phoenix-utils'
import { Button } from '../input/Button'
import { useCssBreakpoints } from 'react-use-css-breakpoints'

export function CreateRoomButton(props) {
  const breakpoint = useCssBreakpoints()

  return (
    <Button
      className={props.isSignedIn ? 'centerBtn' : ''}
      // thick={breakpoint === 'sm' || breakpoint === 'md'}
      // xl={breakpoint !== "sm" && breakpoint !== "md"}
      preset='landing'
      onClick={e => {
        e.preventDefault();
        createAndRedirectToNewHub(null, null, false)
      }}
    >
      <FormattedMessage id='create-room-button' defaultMessage='Create Room' />
    </Button>
  )
}
