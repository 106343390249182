import React from "react";
import PropTypes from "prop-types";

import configs from "../../utils/configs";
// import { ReactComponent as HmcLogo } from "../icons/HmcLogo.svg";
import { isHmc } from "../../utils/isHmc";
import hpcl from "../../assets/images/hpmetaverse.jpg";

export function AppLogo({ className, forceConfigurableLogo }) {
  if (isHmc() && !forceConfigurableLogo) {
    return (
      <img
        className={className}
        alt={configs.translation("app-name")}
        src={hpcl}
        style={{ width: "60%", margin: "auto", height: "auto", maxHeight: "100%" }}
      />
    );
  } else {
    return (
      <img
        className={className}
        alt={configs.translation("app-name")}
        src={hpcl}
        style={{ width: "60%", margin: "auto", height: "auto", maxHeight: "100% " }}
      />
    );
  }
}

AppLogo.propTypes = {
  className: PropTypes.string,
  forceConfigurableLogo: PropTypes.bool
};
