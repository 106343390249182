/* eslint-disable */
import firebase from "firebase"

const config = {
  apiKey: "AIzaSyDTjmSeEocZPtrYIymBKgecYzLqFNMAPBo",
  authDomain: "melzo-metaverse.firebaseapp.com",
  projectId: "melzo-metaverse",
  storageBucket: "melzo-metaverse.appspot.com",
  messagingSenderId: "885342918262",
  appId: "1:885342918262:web:87331e930c8e0eef4cf157",
  measurementId: "G-796B3SZ294"
};

firebase.initializeApp(config);

export default firebase;